import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { latencyToCredits } from 'helpers/utils';

export const createEpochVoteBucketRecordTableColumns = (
  bucket: number | undefined,
  epochStartSlot: number | undefined,
  idealLatencies: number[] | undefined
): ColumnDef<number>[] => {
  return [
    {
      accessorFn: (_row, index) => {
        if (epochStartSlot === undefined || bucket === undefined) return;
        let slotIndex = bucket * 1000 + index;
        return epochStartSlot + slotIndex;
      },
      header: 'Slot',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: latency => {
        if (latency === 0) return '-';
        return latency;
      },
      header: 'Latency',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: (_row, index) => {
        let idealLatency = idealLatencies?.at(index);
        if (idealLatency === undefined || idealLatency === 0) return '-';
        return idealLatency;
      },
      header: 'Optimal Latency',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: latency => {
        return latencyToCredits(latency);
      },
      header: 'Earned TVC Credits',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: (_row, index) => {
        let idealLatency = idealLatencies?.at(index);
        if (idealLatency === undefined) return 0;
        return latencyToCredits(idealLatency);
      },
      header: 'Optimal TVC Credits',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: (latency, index) => {
        let idealLatency = idealLatencies?.at(index);
        if (idealLatency === undefined) return 0;
        const earnedCredits = latencyToCredits(latency);
        const optimalCredits = latencyToCredits(idealLatency);
        return optimalCredits - earnedCredits;
      },
      filterFn: (row, columnId, filterValue) => {
        const missedCredits = Number.parseInt(row.getValue(columnId));
        if (Number.isNaN(missedCredits)) return false;
        return missedCredits > 0;
      },
      id: 'missed-credits',
      header: 'Missed TVC Credits',
      meta: {
        cellProps: { className: 'text-body' }
      }
    }
  ];
};

const EpochVoteBucketRecordTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default EpochVoteBucketRecordTable;
